// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-congress-jsx": () => import("./../../../src/pages/congress.jsx" /* webpackChunkName: "component---src-pages-congress-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-our-science-approved-products-jsx": () => import("./../../../src/pages/our-science/approved-products.jsx" /* webpackChunkName: "component---src-pages-our-science-approved-products-jsx" */),
  "component---src-pages-our-science-index-jsx": () => import("./../../../src/pages/our-science/index.jsx" /* webpackChunkName: "component---src-pages-our-science-index-jsx" */),
  "component---src-pages-our-science-kidney-disease-jsx": () => import("./../../../src/pages/our-science/kidney-disease.jsx" /* webpackChunkName: "component---src-pages-our-science-kidney-disease-jsx" */),
  "component---src-pages-our-science-pipeline-jsx": () => import("./../../../src/pages/our-science/pipeline.jsx" /* webpackChunkName: "component---src-pages-our-science-pipeline-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */)
}

