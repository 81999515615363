import React, { useState, useContext } from "react";

const ModalContext = React.createContext();

const useModal = () => {
	const modalContext = useContext(ModalContext);

	if (!modalContext) {
		throw new Error("useModal must be used inside the ModalProvider");
	}

	return modalContext;
};

const ModalProvider = props => {
	const [link, setLink] = useState("");
	const [can_show_modal, SetCanShowModal] = useState(false);

	const showModal = currentLink => {
		setLink(currentLink);
		SetCanShowModal(true);
	};

	const closeModal = () => {
		// delay so link isn't overridden before launch
		setTimeout(() => {
			setLink("");
			SetCanShowModal(false);
		}, 250);
	};

	const value = {
		showModal,
		closeModal,
		link,
		can_show_modal,
	};

	return <ModalContext.Provider value={value} {...props} />;
};

export { useModal, ModalProvider };
